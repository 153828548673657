import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Skeleton, Stack } from "@mui/material";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";

import { LoadingButton } from "../../components/LoadingButton";

interface BreakConfirmationBottomSheetProps {
  action?: NoteAcknowledgementAction;
  isLoading?: boolean;
  shiftId: string;
  workplaceId?: string;
  breakPolicyNoteId: string;
  modalState: UseModalState;
  onAcknowledge: () => Promise<void>;
}

export function BreakConfirmationBottomSheet(props: BreakConfirmationBottomSheetProps) {
  const {
    action = NoteAcknowledgementAction.BOOK_SHIFT,
    isLoading = false,
    shiftId,
    workplaceId,
    breakPolicyNoteId,
    modalState,
    onAcknowledge,
  } = props;

  const { mutateAsync: postBreakPolicyAcknowledgement, isLoading: isAcknowledgingBreakPolicy } =
    usePostPolicyAcknowledgement();

  const { data: facilityNotesData, isInitialLoading: isFacilityNotesLoading } = useFacilityNotes(
    workplaceId ?? "",
    { enabled: isDefined(workplaceId) && isDefined(breakPolicyNoteId) }
  );

  const breakPolicyNote = facilityNotesData?.find((note) => note.noteId === breakPolicyNoteId);

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_CANCELLED, { shiftId });
            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            isLoading={isLoading || isAcknowledgingBreakPolicy || isFacilityNotesLoading}
            size="large"
            variant="contained"
            onClick={async () => {
              logEvent(APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_ACCEPTED, { shiftId });
              await postBreakPolicyAcknowledgement({
                policyAcknowledgementAction: action,
                noteId: breakPolicyNoteId,
              });
              await onAcknowledge();
            }}
          >
            Continue with {action === NoteAcknowledgementAction.BOOK_SHIFT ? "booking" : "clock in"}
          </LoadingButton>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={8}>
          <Illustration type="break" />
          <Title variant="h3" component="h3" sx={{ textAlign: "center" }}>
            30-min break required
          </Title>
          <Stack direction="column" spacing={4}>
            <Text color={(theme) => theme.palette.text.secondary} variant="body1">
              This facility has the following break policy:
            </Text>
            {isFacilityNotesLoading && (
              <Stack direction="column" spacing={1}>
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
              </Stack>
            )}
            {!isFacilityNotesLoading && (
              <Text
                color={(theme) => theme.palette.text.secondary}
                variant="body1"
                sx={{ fontStyle: "italic", lineHeight: 1.25 }}
              >
                {breakPolicyNote?.note?.trim()}
              </Text>
            )}
            <Text
              color={(theme) => theme.palette.text.secondary}
              variant="body1"
              sx={{ lineHeight: 1.25 }}
            >
              This facility requires a mandatory 30-min unpaid break if you work longer than 6
              hours. If you take an incomplete break, you will need to submit additional
              documentation. Payment for worked-through breaks may take up to three business days to
              process.
            </Text>
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
