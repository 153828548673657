import { isDefined } from "@clipboard-health/util-ts";
import { WorkplaceShiftRateNegotiability } from "@src/appV2/OpenShifts/RateNegotiation/types";
import { BookabilityDecision, BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";

import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { isShiftRateNegotiationEnabled } from "../RateNegotiation/isShiftRateNegotiationEnabled";
import { useShiftRateNegotiationConditions } from "../RateNegotiation/useShiftRateNegotiationConditions";

export function useFooterData() {
  const {
    navigateToModal,
    shiftId: shiftIdFromParams,
    shift,
    bookabilityStatus,
    hasMarkedInterest,
  } = useShiftModalsDataContext();

  const { workplace, offer } = shift ?? {};

  const shiftId = shift?.id ?? shiftIdFromParams;

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;

  const isInstantBookDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
  ]);

  const needsCriteriaBeforeMarkingInterest =
    isInstantBookDisabled &&
    !isBookable &&
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.BLOCKED &&
    bookabilityStatus?.attributes.bookability.unmetCriteria.length > 1;

  const isTestAccount = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT,
  ]);

  const rateNegotiationConditions = useShiftRateNegotiationConditions();
  const isRateNegotiationEnabled =
    isDefined(shift) &&
    isDefined(workplace) &&
    isShiftRateNegotiationEnabled(
      {
        shiftStart: shift.attributes.start,
        workplaceShiftRateNegotiability:
          workplace.attributes.rateNegotiation ?? WorkplaceShiftRateNegotiability.OFF,
        workplaceId: workplace.id,
        workplaceState: workplace.attributes.location.state,
        workplaceMsa: workplace.attributes.location.msa,
        isShiftChargeUpdatedManually: shift.attributes.isChargeUpdatedManually,
      },
      rateNegotiationConditions
    );

  const showMarkInterestButton =
    !needsCriteriaBeforeMarkingInterest && isInstantBookDisabled && !hasMarkedInterest;

  const shouldHideBookCta =
    (isBookable && (isInstantBookDisabled || isTestAccount)) || showMarkInterestButton;

  return {
    isBookable,
    isTestAccount,
    isRateNegotiationEnabled,
    shouldHideBookCta,
    shift,
    shiftId,
    workplace,
    offer,
    navigateToModal,
    showMarkInterestButton,
  };
}
