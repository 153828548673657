import { List } from "@clipboard-health/ui-components";
import { Box } from "@mui/material";
import { type RecentColleague } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { useHistory } from "react-router-dom";

import { SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkingThisShiftListItem } from "../../WorkWithFriends/components/WorkingThisShiftListItem";
import { type WorkerPublicProfile } from "../../WorkWithFriends/hooks/useGetWorkerPublicProfile";
import { type ShiftType } from "../constants";
import { ShiftDurationListItem } from "../DurationListItem";
import { ShifttHighlightsAndMapListItem } from "../HighlightsAndMapListItem";
import { type TimeSlot } from "../Open/types";
import { type ShiftSlotCoalesced } from "../Open/useGetShiftsSlotV2";
import type { ModalShiftSpecificationData, ModalWorkplaceData } from "../types";
import { ShiftWorkplaceListItem } from "../WorkplaceListItem";
import { ShiftBottomSheetBookabilityCardsContainer } from "./BookabilityCardsContainer";
import { ShiftBottomSheetSpecification } from "./Specification";

interface ShiftBottomSheetSheetContentProps {
  shiftId: string;
  shiftType: ShiftType;
  shiftTimeSlot: TimeSlot;
  shiftStartIso: string;
  shiftEndIso: string;
  shiftDurationInHours: number;
  workplace: ModalWorkplaceData;
  bookabilityStatus: BookabilityStatusItem;
  hasBlockingRequirements: boolean;
  hasMarkedInterest?: boolean;
  shiftFailedLoading: boolean;
  hasHoursRestrictionConflict: boolean;
  shiftSlots?: ShiftSlotCoalesced;
  friendsMap?: Map<string, WorkerPublicProfile>;
  recentColleaguesMap?: Map<string, RecentColleague>;
  shiftSpecifications?: ModalShiftSpecificationData[];
  outboundFriendRequestsSet?: Set<string>;
}

export function ShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const {
    shiftId,
    shiftTimeSlot,
    shiftStartIso,
    shiftEndIso,
    shiftDurationInHours,
    workplace,
    bookabilityStatus,
    shiftType,
    hasHoursRestrictionConflict,
    shiftFailedLoading,
    hasMarkedInterest,
    hasBlockingRequirements,
    shiftSlots,
    friendsMap,
    recentColleaguesMap,
    shiftSpecifications,
    outboundFriendRequestsSet,
  } = props;

  const {
    name: workplaceName,
    lateCancellation,
    location: workplaceLocation,
    requiresLunchBreak,
    providesRestBreaks,
  } = workplace.attributes;

  const history = useHistory();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { period: lateCancellationPeriod, feeHours: lateCancellationFeeHours } =
    lateCancellation ?? {};

  return (
    <Box sx={{ overflowY: "auto" }}>
      <ShiftBottomSheetBookabilityCardsContainer
        shiftId={shiftId}
        shiftType={shiftType}
        shiftStartIso={shiftStartIso}
        workplace={workplace}
        bookabilityStatus={bookabilityStatus}
        hasHoursRestrictionConflict={hasHoursRestrictionConflict}
        shiftFailedLoading={shiftFailedLoading}
        hasMarkedInterest={hasMarkedInterest}
        hasBlockingRequirements={hasBlockingRequirements}
      />

      <List sx={{ px: 6 }}>
        <ShiftDurationListItem
          shiftTimeSlot={shiftTimeSlot}
          shiftStartIso={shiftStartIso}
          shiftEndIso={shiftEndIso}
          shiftDurationInHours={shiftDurationInHours}
          workplaceTimeZone={workplaceLocation.timezone}
        />

        {shiftSpecifications?.map((specification) => (
          <ShiftBottomSheetSpecification
            key={`${specification.category}-${specification.requirement}`}
            category={specification.category}
            requirement={specification.requirement}
          />
        ))}

        <ShiftWorkplaceListItem
          workplaceId={workplace.id}
          workplaceName={workplaceName}
          workplaceType={workplace.attributes.type ?? "America/Los_Angeles"}
          workplaceLocation={workplaceLocation}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
              workplaceId: workplace.id,
              trigger: "Shift Bottom Sheet Highlights Card",
            });

            history.push(
              generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
                workplaceId: workplace.id,
              })
            );
          }}
        />

        <ShifttHighlightsAndMapListItem
          workplaceId={workplace.id}
          workplaceName={workplaceName}
          workplaceLocation={workplaceLocation}
          lateCancellationPeriod={lateCancellationPeriod ?? 0}
          lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
          requiresLunchBreak={requiresLunchBreak}
          providesRestBreaks={providesRestBreaks}
        />

        <WorkingThisShiftListItem
          shiftSlots={shiftSlots}
          friendsMap={friendsMap}
          recentColleaguesMap={recentColleaguesMap}
          outboundFriendRequestsSet={outboundFriendRequestsSet}
        />
      </List>
    </Box>
  );
}
