import { ListItem, ListItemIcon, ListItemText } from "@clipboard-health/ui-components";
import { getShiftRequirementLabelByCategory } from "@src/appV2/Shifts/Shift/ShiftSpecification/utils";
import { type ShiftSpecificationCategory } from "@src/appV2/Shifts/Shift/types";

interface ShiftBottomSheetSpecificationProps {
  category: ShiftSpecificationCategory;
  requirement: string;
}

export function ShiftBottomSheetSpecification(props: ShiftBottomSheetSpecificationProps) {
  const { category, requirement } = props;

  return (
    <ListItem divider>
      <ListItemIcon iconType="info" />
      <ListItemText
        primary={getShiftRequirementLabelByCategory(category)}
        secondary={requirement}
      />
    </ListItem>
  );
}
