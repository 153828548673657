/* eslint-disable import/max-dependencies */
import { BottomSheet } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined, isNil } from "@clipboard-health/util-ts";
import { Box, Fade, useTheme } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useIsOnCallWorkplace";
import { CSSTransition } from "react-transition-group";

import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { convertShiftToSlotId } from "../Open/useGetShiftsSlotV2";
import { ShiftBottomSheetCardsWrapper } from "./CardsWrapper";
import { ShiftBottomSheetFooter } from "./Footer";
import { ShiftBottomSheetHeader } from "./Header";
import { OnCallShiftBottomSheetContent } from "./OnCallShift/Content";
import { OnCallShiftBottomSheetFooter } from "./OnCallShift/Footer";
import { OnCallShiftBottomSheetHeader } from "./OnCallShift/Header";
import { OnCallShiftBottomSheetSkeleton } from "./OnCallShift/SheetSkeleton";
import { ShiftBottomSheetContent } from "./SheetContent";
import { ShiftBottomSheetSkeleton } from "./SheetSkeleton";
import { ShiftFailedLoadingCard } from "./ShiftFailedLoadingCard";
import { ShiftNotBookableCard } from "./ShiftNotBookableCard";

export interface ShiftBottomSheetProps {
  modalState: UseModalState;
}

/**
 * A bottom sheet container for displaying shift details.
 *
 * The shift data can come from two sources:
 * 1. Directly via props when opened from a shift card
 * 2. Fetched from the API using the shift ID when:
 *    - Navigating between workplace views and browsing different shifts
 *    - Using back button in workplace modals going back to various shifts visited earlier
 *    - Opening a shift directly via URL
 */
// eslint-disable-next-line complexity
export function ShiftBottomSheet(props: ShiftBottomSheetProps) {
  const { modalState } = props;

  const theme = useTheme();

  const {
    shift,
    shiftId,
    bookabilityStatus,
    isLoadingShiftModalsData,
    hasHoursRestrictionConflict,
    shiftFailedLoading,
    blockingRequirements,
    hasMarkedInterest,
    hasBlockingRequirements,
    shiftSlots,
    friendsMap,
    recentColleaguesMap,
    outboundFriendRequestsSet,
  } = useShiftModalsDataContext();

  const { workplace } = shift ?? {};

  // temporarily show loading state for blocking requirements not being fetched. This will be removed once we pipe
  // the blocking requirements to the bottom sheet from the list view
  const isLoading =
    isNil(blockingRequirements) ||
    ((!isDefined(shift) || !isDefined(bookabilityStatus)) && isLoadingShiftModalsData);

  const isOnCallShift = useIsOnCallWorkplace(shift?.workplace.id);

  useLogEffect(
    APP_V2_USER_EVENTS.SHIFT_DETAILS_VIEWED,
    { shiftId, shift, bookabilityStatus },
    { enabled: modalState.modalIsOpen && isDefined(shift) && !isLoading }
  );

  useLogEffect(
    APP_V2_USER_EVENTS.SHIFT_DETAILS_FAILED_TO_LOAD,
    { shiftId, bookabilityStatus },
    { enabled: modalState.modalIsOpen && shiftFailedLoading }
  );

  return (
    <BottomSheet
      modalState={modalState}
      footer={isOnCallShift ? <OnCallShiftBottomSheetFooter /> : <ShiftBottomSheetFooter />}
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shift?.id });
      }}
    >
      {isOnCallShift ? (
        <OnCallShiftBottomSheetHeader onClose={modalState.closeModal} />
      ) : (
        isDefined(shift) &&
        isDefined(workplace) && (
          <ShiftBottomSheetHeader
            shiftSlots={
              shiftSlots?.[
                convertShiftToSlotId({
                  start: shift.attributes.start,
                  end: shift.attributes.end,
                  facilityId: workplace.id,
                })
              ]
            }
            onClose={() => {
              modalState.closeModal();
            }}
          />
        )
      )}

      {isDefined(shift) &&
        isDefined(workplace) &&
        isDefined(bookabilityStatus) &&
        !isLoading &&
        (isOnCallShift ? (
          <OnCallShiftBottomSheetContent
            shiftId={shift.id}
            shiftType={shift.attributes.type}
            qualificationName={shift.attributes.qualification}
            workplace={workplace}
            bookabilityStatus={bookabilityStatus}
          />
        ) : (
          <ShiftBottomSheetContent
            shiftId={shift.id}
            shiftType={shift.attributes.type}
            shiftTimeSlot={shift.attributes.timeSlot}
            shiftStartIso={shift.attributes.start}
            shiftEndIso={shift.attributes.end}
            shiftDurationInHours={shift.attributes.durationInHours}
            workplace={workplace}
            bookabilityStatus={bookabilityStatus}
            hasHoursRestrictionConflict={hasHoursRestrictionConflict}
            shiftFailedLoading={shiftFailedLoading}
            hasMarkedInterest={hasMarkedInterest}
            hasBlockingRequirements={hasBlockingRequirements}
            shiftSlots={
              shiftSlots?.[
                convertShiftToSlotId({
                  start: shift.attributes.start,
                  end: shift.attributes.end,
                  facilityId: workplace.id,
                })
              ]
            }
            friendsMap={friendsMap}
            recentColleaguesMap={recentColleaguesMap}
            outboundFriendRequestsSet={outboundFriendRequestsSet}
            shiftSpecifications={shift.attributes.specifications}
          />
        ))}
      {
        // This error state is displayed if the modal is opened
        // without initial data and the shift data is not available.
        !isDefined(shift) && shiftFailedLoading && (
          <ShiftBottomSheetCardsWrapper>
            {!isDefined(bookabilityStatus) && <ShiftFailedLoadingCard />}
            {isDefined(bookabilityStatus) && <ShiftNotBookableCard />}
          </ShiftBottomSheetCardsWrapper>
        )
      }

      <CSSTransition
        unmountOnExit
        in={isLoading && !shiftFailedLoading}
        timeout={theme.transitions.duration.leavingScreen}
      >
        <Fade in={isLoading && !shiftFailedLoading}>
          <Box>
            {isOnCallShift ? <OnCallShiftBottomSheetSkeleton /> : <ShiftBottomSheetSkeleton />}
          </Box>
        </Fade>
      </CSSTransition>
    </BottomSheet>
  );
}
/* eslint-enable import/max-dependencies */
