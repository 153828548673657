import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftBottomSheetShiftRequestedCard() {
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Request sent"
      description="This workplace must approve your request to book."
      illustrationType="request-sent"
    />
  );
}
